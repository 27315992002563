<template>
  <v-row class="flex-wrap justify-center fixed">
    <v-col cols="6" class="">
      <v-row class="mb-15">
        <v-col cols="8" class="cLogo">
          <v-img class="logo" src="../../assets/ePrev.png" />
        </v-col>
      </v-row>

      <!-- back button -->
      <v-row class="">
        <v-col cols="12 btn">
          <div class="subtitle-2 font-weight-regular gray--text">
            <v-btn
              text
              color="gray"
              @click="
                $router.push({
                  name: 'login',
                })
              "
            >
              <v-icon>mdi-arrow-left</v-icon> Voltar para o início
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- label -->
      <div v-if="confirmation == false">
        <v-row class="mt-15">
          <v-col cols="12 cLogo">
            <div class="text-h5 font-weight-regular">Redefinição de senha</div>
            <div class="subtitle font-weight subt">Insira uma nova senha</div>
          </v-col>
        </v-row>

        <!-- input -->

        <v-row class="mt-15">
          <v-col cols="12 cLogo">
            <v-form
              class="form-app form-reset-password"
              @submit.prevent="reset"
            >
              <v-text-field
                v-model="password"
                type="password"
                class="input"
                id="password"
                placeholder="Senha"
                outlined
                clearable
              />

              <v-text-field
                v-model="password_confirmation"
                type="password"
                class="input"
                id="password_confirmation"
                placeholder="Confirmação de senha"
                outlined
                clearable
              />

              <v-btn
                class="input white--text"
                height="50"
                color="#1c3b7b"
                type="submit"
                onClick="submit"
              >
                Atualizar senha
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <v-row class="mt-15">
          <v-col cols="10 cLogo">
            <div class="text-h5 font-weight-regular">Senha redefinida!</div>
            <div class="subtitle font-weight-light text-justify mt-7">
              Sua senha foi atualizada. Você já pode utilizar a nova senha para
              fazer login no sistema pelo site ou pelo seu aplicativo.
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col cols="6" class="pa-0" v-if="!$vuetify.breakpoint.mobile">
      <v-img
        class="wallpaper"
        :lazy-src="require('../../assets/login.png')"
        :src="require('../../assets/login.png')"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      password_confirmation: "",
      error: "",
      confirmation: false,
    };
  },
  methods: {
    async reset() {
      try {
        await this.$axios.put(
          "reset_password",
          {
            password: this.password,
            password_confirmation: this.password_confirmation,
          },
          {
            params: {
              token: this.$route.params.token,
            },
          }
        );

        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "sucessful",
          title: "MIS",
          text: "Senha alterada com sucesso.",
        });

        this.confirmation = true;
      } catch (error) {
        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar alterar a senha ",
        });
        // for (let i in this.formatErrors(error.response.data.errors)) {
        //   this.$swal({
        //     toast: true,
        //     position: "center",
        //     showConfirmButton: true,
        //     icon: "error",
        //     title: "MIS",
        //     text: `${i.charAt(0).toUpperCase() + i.slice(1)} ${
        //       this.formatErrors(error.response.data.errors)[i]
        //     }`,
        //   });
        // }
        console.log("Reset password error: ", error);
      }
    },
    resetSuccessful() {
      this.error = "";
      this.password = "";
      this.password_confirmation = "";
    },
    resetFailed(error) {
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        "Something went wrong";
    },
    formatErrors(errors) {
      const formattedErrors = {};

      Object.keys(errors).forEach((key) => {
        formattedErrors[key] = errors[key][0];
      });

      return formattedErrors;
    },
    // checkPasswordToken () {
    //   // checar se token é valido e se o token existe
    //   this.$axios.get(`/resource/password/edit`, { params: { reset_password_token: this.$route.params.token }})
    //     .then(response => this.checkPasswordTokenSuccessful(response))
    //     .catch(error => this.checkPasswordTokenFailed(error))
    // }
  },
};
</script>
<style scoped>
.subt {
  color: #1c3b7b;
}
.btn {
  margin-left: 6vh;
}
.cLogo {
  margin-left: 100px;
}
.logo {
  width: 304px;
  height: 75px;
  padding: 95px;
}
.input {
  width: 35vw;
  color: #1c3b7b;
}
.wallpaper {
  background-image: url("../../assets/login.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  flex: 1;
}
</style>